import http from '@/config/http.js';
import staticDic from './staticDic.js';

const state = {
  ...staticDic,
}

const getters = {
  getDic(state) {
    return function (type, value) {
      return state[type] ? state[type].reduce((pre, cur) => {
        return cur.value == value ? pre.concat(cur.label) : pre
      }, "") : null
    }
  },
}

const mutations = {
  initDic(state) { },
}

const actions = {
  // GET /account/sys/org/getOrgList
  getOrgList({ state }) {
    return new Promise((resolve, reject) => {
      http.get("/account/sys/org/getOrgList").then(res => {
        if (res.code == state.SUCCESS_CODE) {
          state._ORGLIST = res.data.map(item => {
            return {
              label: item.name,
              value: item.id
            }
          })
          resolve(res.code);
        } else {
          resolve(res.code);
        }
      }).catch(err => {
        reject(err)
        console.log(err);
      })
    })
    // return http.get("/account/sys/org/getOrgList")
  },
  // 获取字典
  getDics({ state }, params) {
    return new Promise((resolve, reject) => {
      http.get("/account/sys/dict/listByType", { params: { type: params.type } }).then(res => {
        if (res.code == state.SUCCESS_CODE) {
          state[params.typeName] = res.data.map(item => {
            return {
              label: item.name,
              value: item.code
            }
          })
        }
        resolve(res.code);
      }).catch(err => {
        reject(err)
      })
    })
  },
  // 获取状态字典
  getStatusDics({ state }, params) {
    return new Promise((resolve, reject) => {
      http.get("/account/sys/dict/listByType", { params: { type: params.type } }).then(res => {
        if (res.code == state.SUCCESS_CODE) {
          state[params.typeName] = res.data.map(item => {
            return {
              label: item.dictValue,
              value: item.dictKey
            }
          })
        }
        resolve(res.code);
      }).catch(err => {
        reject(err)
      })
    })
  },
}



export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
