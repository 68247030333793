import auth from './auth';
import user from './user';
import dic from "./dic/index";

const modules = {
    dic,
    auth,
    user,
}

export default modules;