<template>
  <div class="c-table">
    <el-table
      height="100%"
      :data="data"
      :row-key="rowKey || null"
      highlight-current-row
      :header-cell-style="{ backgroundColor: '#E7F0FC' }"
      :header-row-style="{ color: '#3c3c3c' }"
      :row-style="{ color: '#5A5A5A','font-weight':500 }"
      :cell-style="cellStyle"
      @row-click="rowClick"
      @selection-change="selectionChange"
      @sort-change="sortChange"
      style="width: 100%"
      ref="cTable"
      :stripe="stripe"
    >
      <el-table-column
        v-if="$listeners.selectionChange"
        type="selection"
        width="50"
        align="center"
      ></el-table-column>
      <el-table-column
        v-if="index"
        label="序号"
        type="index"
        width="50"
        align="center"
      ></el-table-column>
      <template v-for="(item, index) in tableColumns">
        <c-table-column
          v-if="item.children && item.children.length"
          :key="index"
          :tableColumn="item"
          :sortable="item.sortable"
          :align="item.align || 'center'"
        ></c-table-column>

        <template v-else-if="item.name && item.show">
          <c-table-slot :item="item" :key="index"></c-table-slot>
        </template>
        <el-table-column
          label="操作"
          fixed="right"
          v-else-if="item.name == 'contr'"
          :key="index"
          header-align="center"
          :min-width="item.minWidth || '120px'"
          :width="item.width"
        >
          <template slot-scope="scope">
            <div class="contr-btn-group">
              <!-- <div
                class="contr-btn"
                title="详情"
                @click="handeShowDetail(scope.row)"
                v-if="$listeners.showDetail"
              >
                <img src="../../assets/images/icon/detail.png" />
              </div> -->
              <div
                class="contr-btn"
                title="授权"
                @click="handleAuthorization(scope.row)"
                v-if="$listeners.authorization"
              >
                <i class="iconfont icon-shouquan"></i>
              </div>
              <div
                class="contr-btn"
                title="重置密码"
                @click="handleResetPwd(scope.row)"
                v-if="$listeners.resetPwd"
              >
                <i class="iconfont icon-zhongzhimima"></i>
              </div>
              <div
                v-if="$listeners.edit"
                class="contr-btn"
                title="编辑"
                @click="handeEdit(scope.row)"
              >
                <i class="iconfont icon-bianji"></i>
              </div>
              <div
                v-if="$listeners.delete"
                class="contr-btn"
                title="删除"
                @click="handleDelete(scope.row.id)"
              >
                <i class="iconfont icon-shanchu"></i>
              </div>
              <div
                v-if="$listeners.config"
                class="contr-btn"
                title="规则配置"
                @click="handleConfig(scope.row)"
              >
                <i class="iconfont icon-xitongguanli1"></i>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          v-else-if="item.show"
          show-tooltip-when-overflow
          :class-name="item.className"
          :key="index"
          :width="item.width || 'auto'"
          :min-width="item.minWidth"
          v-bind="item"
          :align="item.align || 'center'"
        ></el-table-column>
      </template>
    </el-table>
    <!-- background prev-text="上一页"
      next-text="下一页" -->
    <el-pagination
      v-if="$listeners.change"
      layout="prev, pager, next"
      :total="pagination.total"
      :page-size="pagination.pageSize"
      :current-page="pagination.pageNum"
      :page-sizes="[10, 50, 100, 200, 1000, 5000]"
      @size-change="sizeChange"
      @current-change="currentChange"
    />
  </div>
</template>

<script>
import CTableColumn from "./src/CTableColumn.vue";
import CTableSlot from "./src/CTableSlot.vue";

export default {
  props: {
    data: {
      type: Array,
      required: true,
    },
    tableColumns: {
      type: Array,
      required: true,
    },
    rowKey: String,
    index: Boolean,
    stripe: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Object,
      default() {
        return {
          layout: "layout",
          total: "total",
          pageSize: "pageSize",
          currentPage: "currentPage",
          pageSizes: "pageSizes",
        };
      },
    },
    pagination: {
      type: Object,
      default() {
        let options = this.options;
        return {
          [options.layout]: "total, sizes, prev, pager, next, jumper",
          [options.total]: 0,
          [options.pageSize]: 10,
          [options.pageNum]: 1,
          [options.pageSizes]: [10, 20, 30, 40, 50],
        };
      },
    },
    cellStyle: Function,
  },
  provide() {
    return {
      cTable: this,
    };
  },
  watch: {
    tableColumns: {
      handler(val) {
        this.$nextTick(() => {
          //在数据加载完，重新渲染表格
          this.$refs["cTable"].doLayout();
        });
      },
      deep: true,
      immediate: true,
    },
  },
  components: {
    CTableColumn,
    CTableSlot,
  },
  created() {
    console.log(this.pagination);
  },
  methods: {
    sizeChange(val) {
      this.pagination.pageSize = val;
      this.$emit("change", {
        pageSize: val,
      });
    },
    currentChange(val) {
      this.pagination.pageNum = val;
      this.$emit("change", {
        pageNum: val,
      });
    },
    rowClick(val){
      this.$emit("rowClick", val);
    },
    selectionChange(val) {
      this.$emit("selectionChange", val);
    },
    sortChange({ column, prop, order }) {
      this.$emit("sortChange", { column, prop, order });
    },
    // 详情
    handeShowDetail(data) {
      this.$emit("showDetail", data);
    },
    // 授权
    handleAuthorization(data) {
      this.$emit("authorization", data);
    },
    // 重置(密码)
    handleResetPwd(data) {
      this.$emit("resetPwd", data);
    },
    // 编辑
    handeEdit(data) {
      this.$emit("edit", data);
    },
    // 删除
    handleDelete(id) {
      this.$emit("delete", id);
    },
    // 规则配置(专业调整)
    handleConfig(data) {
      this.$emit("config", data);
    },
  },
};
</script>

<style lang="less">
.c-table {
  height: 100%;
  display: flex;
  flex-direction: column;
  .el-table {
    flex: 1;
    // margin-bottom: 20px;
    // .cell {
    // height: 25px !important;
    // }
  }
  .el-pagination {
    padding-top: 10px;
    text-align: center;
  }
  .el-pager li {
    min-width: 40px !important;
    height: 40px !important;
    line-height: 40px !important;
  }
  .el-pagination button,
  .el-pagination span:not([class*="suffix"]) {
    padding: 0 10px !important;
    height: 40px !important;
    line-height: 40px !important;
  }
  .el-table td,
  .el-table th.is-leaf {
    transition: all ease 0.38s;
    // border-bottom: none;
  }
  .el-table::before {
    height: 0;
  }
  .el-table--striped
    .el-table__body
    tr.el-table__row--striped
    td.el-table__cell {
    background-color: #f3f8ff;
  }
  .leave-btn {
    padding: 4px 10px;
  }
  .contr-btn {
    img {
      width: 24px;
      height: 24px;
      vertical-align: bottom;
    }
  }
  .opt-detail,
  .opt-edit {
    color: #3a7eef;
    cursor: pointer;
  }

  .opt-delete {
    color: #f66161;
    cursor: pointer;
  }
  .opt-other {
    color: #0DB373;
    cursor: pointer;
  }
}
</style>