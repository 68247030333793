<template>
  <el-row class="c-form" :gutter="20">
    <el-form
      size="small"
      :model="value"
      ref="cForm"
      :rules="formRules"
      :label-width="labelWidth"
      :label-position="labelPosition"
      @submit.native.prevent
    >
      <el-col :span="24">
        <slot>
          <c-form-item :formItems="formItems" ref="cFormItem" />
        </slot>
      </el-col>
      <el-col :span="24">
        <slot name="custom"></slot>
      </el-col>
      <el-col :span="24">
        <el-form-item label-width="0" class="form-item-btn">
          <slot name="other">
            <el-button
              class="operation-btn"
              v-if="$listeners.submit"
              type="primary"
              @click="submit"
              size="medium"
              >保存</el-button
            >
            <el-button
              class="operation-btn operation-btn-reset"
              v-if="$listeners.resetFields"
              @click="resetFields"
              size="medium"
              plain
              >取消</el-button
            >
          </slot>
        </el-form-item>
      </el-col>
    </el-form>
  </el-row>
</template>

<script>
import CFormItem from "./CFormItem";

export default {
  props: {
    span: {
      type: Number,
      default: 8,
    },
    labelWidth: {
      type: String,
      default: "80px",
    },
    labelPosition: {
      type: String,
      default: "left",
    },
    value: Object,
    formRules: Object,
    formItems: Array,
  },
  components: {
    CFormItem,
  },
  watch: {
    formRules: {
      handler(val) {
        this.$nextTick(() => {
          this.$refs.cForm.resetFields();
        });
      },
      immediate: true,
      deep: true,
    },
  },
  provide() {
    return {
      cForm: this,
    };
  },
  methods: {
    submit(fn) {
      this.$refs.cForm.validate((valid) => {
        if (valid) {
          this.$emit("submit", this.value);
          fn instanceof Function && fn(this.value);
        } else {
          return false;
        }
      });
    },
    resetFields() {
      let self = this;
      this.$emit("resetFields");
      self.$refs.cForm.resetFields();
    },
    clearValidate(){
      this.$refs.cForm.clearValidate();
    },
  },
};
</script>

<style lang="less">
.c-form {
  .form-item-btn {
    text-align: center;
  }
  .operation-btn {
    height: 32px;
    margin-bottom: 18px;
  }
  .operation-btn-reset {
    border: 1px solid #197ce9;
    color: #197ce9;
  }
  .el-form-item--small .el-form-item__content {
    line-height: unset;
  }
}
</style>
