import Vue from 'vue';
import VueRouter from 'vue-router';
import staticRoutes from './staticRoutes';
// import whiteList from "./whiteList";

Vue.use(VueRouter);

const router = new VueRouter({
    mode: 'hash',
    routes: staticRoutes
});

// 解决报错
const originalPush = VueRouter.prototype.push
const originalReplace = VueRouter.prototype.replace
// push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
    if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
    return originalPush.call(this, location).catch(err => err)
}
// replace
VueRouter.prototype.replace = function push(location, onResolve, onReject) {
    if (onResolve || onReject) return originalReplace.call(this, location, onResolve, onReject)
    return originalReplace.call(this, location).catch(err => err)
}


// router.beforeEach((to, from, next) => {
//     let token = localStorage.getItem("bridge-token");
//     if (window.top !== window.self) {
//         // 如果为白名单，直接访问
//         if (whiteList.includes(to.path)) {
//             console.log(to.path)
//             return next();
//         }
//         // 无token
//         if (!token) {
//             let url = "http://121.4.121.9:1191/signin.html"; // welink免登地址，用于获取code
//             let redirectUri = "http://47.97.158.149:30000/#/cas";
//             window.location.href = `${url}?ReturnUrl=${encodeURIComponent(redirectUri)}`;
//         }
//         // 存在token逻辑
//         if (token) {
//             if(to.path === '/login') return next({path: Vue.prototype.$DEFAULT_URL, replace: true});
//             if (to.path.includes('/single') && to.path !== '/single'){
//                 if (localStorage.getItem('menuList').includes(to.path) || to.path.includes('/vtour') || to.path.includes('/detail')){
//                     return next();
//                 } else {
//                     return next({ path: '/login', replace: true });
//                 }
//             }
//             return next();
//         }
//     } else {
//         // 如果为白名单，直接访问
//         if (whiteList.includes(to.path)) {
//             console.log("whiteList")
//             return next();
//         }
//         // 无token
//         if (!token) {
//             if(to.path === '/login') return next();
//             return next({ path: '/login', replace: true });
//         }
//         // 存在token逻辑
//         if (token) {
//             if(to.path === '/login') return next({path: Vue.prototype.$DEFAULT_URL, replace: true});
//             if (to.path.includes('/single') && to.path !== '/single'){
//                 if (localStorage.getItem('menuList').includes(to.path) || to.path.includes('/vtour') || to.path.includes('/detail')){
//                     return next();
//                 } else {
//                     return next({ path: '/login', replace: true });
//                 }
//             }
//             return next();
//         }
//     }
// });
export default router;
