import axios from 'axios';
import { Message } from "element-ui";
import router from "../router";


const service = axios.create({
    // baseURL: 'api',
    headers: {
        'Content-Type': 'application/json',
    }
})

service.interceptors.request.use(
    config => {
        let token = localStorage.getItem('bridge-token');
        if (token) {
            config.headers.token = token;
        }
        let url = localStorage.getItem('baseUrl');
        if (!config.url.startsWith('/bridge')) config.url = url + config.url;
        return config;
    },
    error => {
        return Promise.reject(error);
    }
)

service.interceptors.response.use(
    response => {
        console.log(response)
        if (response.data.code === 401 || response.data.code === 302) {
            localStorage.clear();
            router.push({
                path: '/login'
            })
        }
        else if(response.data.code === 403){
            //清除sessionStorage对象所有值
            localStorage.clear();
            //跳转
            router.push({
                path: '/login'
            })
        }
        else if(response.data.code === 500){
            // Message.error("服务器错误！")
        }
        // 增加文件判断
        if(response.headers['content-disposition']) {
            return Promise.resolve(response);
        }
        return Promise.resolve(response.data);
    },
    error => {
        console.log(error)
        if (error && error.response) {
            // 根据错误码跳转到对应的页面
            switch (error.response.status) {
                case 401:
                    router.push("/error/401");
                    break;
                case 403:
                    localStorage.clear();
                    router.push("/login");
                    Message({ message: "token已过期！", type: "warning" });
                    break;
                case 404:
                    Message({ message: "请求不存在！", type: "warning" });
                    break;
                // case 500:
                //     Message({ message: "服务器错误！", type: "warning" });
                //     break;
                default:
                    return Promise.reject(error);
            }
        } else {
            if (error.message.includes("timeout")) {
                Message({
                    message: "请求超时!请检查网络或刷新浏览器重试",
                    type: "error",
                    duration: 4000,
                });
            }
            if (error.message.includes("Network Error")) {
                Message({
                    message: "网络错误",
                    type: "error",
                    duration: 4000,
                });
            } else {
                Message({
                    message: error.message,
                    type: "error",
                    duration: 4000,
                });
            }
            return Promise.reject(error);
        }
    }
)
export default service;
