<template>
  <div class="c-title">
    <span class="title-text">{{ title }}</span>
    <span class="back-btn" @click="handleBack" title="返回" v-show="back">
      <i class="iconfont icon-fanhui1"></i>
    </span>
  </div>
</template>

<script>
export default {
  name: "",
  props: {
    title: String,
    back: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      lastRouter: "",
    };
  },
  created() {},
  mounted() {},
  methods: {
    handleBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="less">
.c-title {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .title-text {
    font-size: 16px;
    color: #1e1e1e;
  }

  .back-btn {
    cursor: pointer;
    i {
      font-size: 26px;
      vertical-align: middle;
      color: #1e1e1e;
    }
    img {
      width: 24px;
      height: 18px;
    }
  }
}
</style>