<template>
  <el-row class="c-search">
    <el-form
      size="medium"
      :model="value"
      ref="cSearch"
      :label-width="labelWidth"
      @submit.native.prevent
    >
      <div class="form-container">
        <div class="search-container">
          <slot>
            <c-search-item :searchItems="searchItems" ref="cSearchItem" />
          </slot>
        </div>
        <div class="opt-container">
          <el-form-item label-width="0">
            <div class="opt-group">
              <el-button
                class="btn-search"
                type="primary"
                @click="search"
                v-if="$listeners.search"
                >查询</el-button
              >
              <el-button
                class="btn-reset"
                @click="reset"
                v-if="$listeners.reset"
                >重置</el-button
              >
              <el-button
                class="btn-export"
                @click="handleExport"
                v-if="$listeners.export"
                >导出</el-button
              >
              <el-button
                class="btn-export"
                @click="handleDelBatch"
                v-if="$listeners.delBatch"
                >批量删除</el-button
              >
              <el-button
                class="btn-export"
                @click="handleDelQuery"
                v-if="$listeners.delQuery"
                >全部删除</el-button
              >
            </div>
          </el-form-item>
        </div>
      </div>
    </el-form>
  </el-row>
</template>

<script>
import CSearchItem from "./src/CSearchItem";

export default {
  props: {
    span: {
      type: Number,
      default: 8,
    },
    labelWidth: {
      type: String,
      default: "80px",
    },
    value: Object,
    searchItems: Array,
  },
  components: {
    CSearchItem,
  },
  provide() {
    return {
      cSearch: this,
    };
  },
  methods: {
    search() {
      this.$emit("search");
    },
    reset() {
      this.$emit("reset");
    },
    handleExport() {
      this.$emit("export");
    },
    handleDelBatch() {
      this.$emit("delBatch");
    },
    handleDelQuery() {
      this.$emit("delQuery");
    }
  },
};
</script>

<style lang="less">
.c-search {
  .operation-btn {
    width: 70%;
    height: 32px;
    margin-bottom: 18px;
  }
  .operation-btn-reset {
    border: 1px solid #197ce9;
    color: #197ce9;
    margin-left: 0;
    &.btn-inline {
      margin-left: 10px;
    }
  }
  .btn-inline {
    width: 40%;
  }
}

.form-container {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  .search-container {
    /*width: calc(100% - 160px);*/
  }
  .opt-container {
    /*width: 160px;*/
    .el-form-item--mini.el-form-item,
    .el-form-item--small.el-form-item {
      margin-bottom: 10px !important;
    }
  }
}
.btn-inline-ss {
  padding-left: 11px;
}
.opt-group {
  display: flex;
  align-items: center;
  padding-left: 10px;
  // justify-content: space-around;
  .opt-btn {
    cursor: pointer;
    margin: 0 5px;
    i {
      font-size: 30px;
      color: #febc8e;
      vertical-align: middle;
    }
  }
  .btn-search {
    background-color: #3382ed;
    border-color: #3382ed;
    border-radius: 2px;
  }
  .btn-reset {
    background-color: #fff;
    border: 1px solid #3382ed;
    border-radius: 2px;
    color: #3382ed;
  }
  .btn-export {
    background-color: #e6effb;
    border: 1px solid #3382ed;
    border-radius: 2px;
    color: #3382ed;
  }
}
</style>
