<template>
  <el-table-column
    show-tooltip-when-overflow
    v-bind="tableColumn"
    align="center"
    :sortable="tableColumn.sortable"
  >
    <template v-for="(item, index) in tableColumn.children">
      <c-table-column
        v-if="item.children && item.children.length"
        :key="index"
        :tableColumn="item"
      ></c-table-column>
      <template v-else-if="item.name">
        <c-table-slot :item="item" :key="index"></c-table-slot>
      </template>
      <el-table-column
        v-else
        show-tooltip-when-overflow
        :key="index"
        v-bind="item"
        align="center"
      ></el-table-column>
    </template>
  </el-table-column>
</template>

<script>
import CTableSlot from "./CTableSlot.vue";
export default {
  name: "CTableColumn",
  props: {
    tableColumn: {
      type: Object,
      required: true,
    },
  },
  components: {
    CTableSlot,
  },
};
</script>


