<template>
  <div id="app">
    <router-view v-if="isRouterAlive"></router-view>
  </div>
</template>

<script>
import { Transform } from "stream";

export default {
  name: "App",
  provide() {
    return {
      reload: this.reload,
    };
  },
  data() {
    return {
      isRouterAlive: true,
    };
  },
  mounted(){
    // window.addEventListener('resize',()=>{
    //   this.transform();
    // })
  },
  methods: {
    reload() {
      this.isRouterAlive = false;
      this.$nextTick(() => (this.isRouterAlive = true));
    },
    transform() {
      let baseWidth = 1920;
      let baseHeight = 1080;
      let html = document.querySelector("html");
      // let cHeight = document.documentElement.clientHeight;
      let transform =
        "scale(" +
        window.innerWidth / baseWidth +
        "," +
        window.innerHeight / baseHeight +
        ")";
      html.style.transform = transform;
    },
  },
};
</script>

<style lang="less">
@import url("./assets/styles/index.less");
</style>

<style>
.el-message-box__status {
  top: 12px;
}
.el-message-box__message p:last-child {
  font-size: 12px;
}
.el-cascader-panel .el-radio {
  width: 100%;
  height: 100%;
  z-index: 10;
  position: absolute;
  top: 10px;
  right: 10px;
}
.el-cascader-panel .el-radio__input {
  visibility: hidden;
}
/* 这个样式针对IE*/
.el-cascader-panel .el-cascader-node__postfix {
  top: 10px;
}
</style>
