import { render, staticRenderFns } from "./CSearchItem.vue?vue&type=template&id=768c58e8"
import script from "./CSearchItem.vue?vue&type=script&lang=js"
export * from "./CSearchItem.vue?vue&type=script&lang=js"
import style0 from "./CSearchItem.vue?vue&type=style&index=0&id=768c58e8&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports