import http from '../../config/http.js';
const state = {
  menuList: [], // 菜单列表
  permissionList: [], // 权限列表,
  routes: [], // 路由列表
  buttonPermissionList: []
};

const mutations = {
  setMenuList(state, arr) {
    state.menuList = arr;
  },
  setPer(state, arr) {
    state.permissionList = arr;
  },
  setRoutes(state, arr) {
    state.routes = arr;
  },
  resetState(state) {
    state.menuList = [];
    state.permissionList = [];
    state.routes = [];
    state.buttonPermissionList = [];
  }
};

const actions = {
  // 初始化菜单
  initMenu({
    state, commit
  }, roleId) {
    return new Promise(resolve => {
      commit('resetState');
      let params = { roleId, platform: 'student-manage-pc' }
      http.get("/account/sys/menu/getByRoleId", { params }).then(res => {
        if (res.code == 0) {
          // console.log(res)
          // 设置菜单
          commit('setMenuList', res.data);
          // 设置路由权限数组
          let permissionList = [];
          flatMenuList(res.data, permissionList);
          commit('setPer', permissionList);
          // 设置路由
          let routes = initRoutes(state.permissionList);
          commit('setRoutes', routes);
          resolve(res.data);
        } else {
          // console.log(res.msg)
        }
      }).catch(err => {
        console.log(err)
      })
    })
  },
};
function flatMenuList(menulist, resultlist = []) {
  menulist.forEach(item => {
    resultlist.push(Object.assign({}, item, {
      children: []
    }));
    if (item.children && item.children.length) {
      flatMenuList(item.children, resultlist);
    }
  });
}
function flatButtonList(menulist, resultlist = []) {
  menulist.forEach(item => {
    if (item.permList && item.permList.length) {
      item.permList.forEach(perm => {
        resultlist.push(perm)
      })
    }
    if (item.children && item.children.length) {
      flatButtonList(item.children, resultlist);
    }
  })
}

function initRoutes(permissionList) {
  // console.log('----------------------初始化路由----------')
  // console.log(permissionList)
  let children = []
  permissionList.forEach(item => {
    if (item.url && item.type != 0) {
      let route = {
        path: item.url,
        component: () => Promise.resolve(require("@/page/management" + item.component).default),
      }
      children.push(route);
    }
  })
  return children;
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
