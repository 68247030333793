<template>
  <div class="lxb-layout mb-3">
    <el-collapse v-model="active" style="border: 0" accordion>
      <el-collapse-item name="1">
        <template slot="title">
          <div class="title-container">
            <!-- 头部左侧内容 -->
            <slot name="title-left"></slot>
            <span class="sub-title">查询搜索</span>
          </div>
          <!-- 头部右侧内容 -->
          <slot name="title-right"></slot>
        </template>
        <div style="padding: 10px 10px 0 10px">
          <!-- 折叠区内容 -->
          <slot name="body"></slot>
        </div>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>

<script>
export default {
  name: "LCollapseItem",
  props: {
    show: {
      type: Boolean,
      default: true,
    },
  },
  watch: {
    show: {
      handler(v) {
        if (v === false) {
          this.active = "";
        }
      },
      immediate: true,
    },
  },
  data() {
    return {
      active: "1",
    };
  },
};
</script>

<style lang="less" scoped>
.lxb-layout {
  margin-bottom: 20px;
  /deep/ .el-collapse-item {
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.07);
    border-radius: 10px;
    .el-collapse-item__header {
      background: #eef6ff;
      width: 100%;
      padding: 0 20px;
      border-radius: 10px 10px 0 0;
    }
    .el-collapse-item__content {
      padding-bottom: 0;
    }
    .el-collapse-item__wrap {
      border-radius: 0 0 10px 10px;
    }
    .el-collapse-item__arrow {
      // display: none;
      color: #409eff;
      font-size: 20px;
      cursor: pointer;
    }
    .sort-arrow {
      width: 20px;
      height: 20px;
    }
    .title-container {
      .title {
        color: #1e1e1e;

        &.title-breadcrumb {
          cursor: pointer;
        }
      }

      .sub-title {
        display: inline-block;
        padding-left: 15px;
        margin-left: 15px;
        color: #5a5a5a;
        position: relative;
        &:before {
          content: "";
          height: 20px;
          width: 1px;
          position: absolute;
          top: 50%;
          left: -1px;
          background-color: #c9c9c9;
          transform: translateY(-50%);
        }
      }
      font-size: 16px;
    }
  }
}
</style>
