
const staticRoutes = [
    {
        path: '/',
        redirect: '/login'
    },
    // 登陆
    {
        path: '/login',
        component: () => import('../page/login')
    },
    // cas登陆
    {
        path: '/cas',
        component: () => import('../page/login/cas.vue')
    },
    // 省平台cas登陆
    {
        path: '/provinceCas',
        component: () => import('../page/login/provinceCas.vue')
    },
    {
        path: '/test',
        component: () => import('../page/test')
    },
    {
        path: '/single',
        component: () => import('../page/single')
    },
    //vr弹窗
    {
        path: '/single/vtour',
        component: () => import('../page/single/components/screen01/vtourIndex')
    },
    {
        path: '/single/vtour/video',
        component: () => import('../page/single/components/screen01/vtour/video')
    },
    {
        path: '/single/vtour/attr',
        component: () => import('../page/single/components/screen01/vtour/attr.vue')
    },
    {
        path: '/single/vtour/chart',
        component: () => import('../page/single/components/screen01/vtour/chart.vue')
    },
    {
        path: '/single/chart',
        component: () => import('../page/single/components/screen01/echartsTest')
    },
    {
        path: '/platform',
        component: () => import('../page/platform')
    },
    // 后台管理页面
    {
        path: '/layout',
        name: 'layout',
        redirect: '/platform/bridge-group',
        component: () => import('../page/layout'),
        children: [
            /**
             * 总平台
             */
            // 桥群管理
            {
                path: '/platform/bridge-group',
                component: () => import('../page/platform/manage/bridge-group')
            },
            /**
             * 单桥
             */
            // 信息总览
            {
                path: '/single/home',
                meta: {
                    showBack: false,
                },
                component: () => import('../page/single/manage/home')
            },
            // 结构监测-环境监测
            {
                path: '/single/structure/environment',
                meta: {
                    showBack: false,
                },
                component: () => import('../page/single/manage/structure/environment')
            },
            // 结构监测-作用监测
            {
                path: '/single/structure/action',
                meta: {
                    showBack: false,
                },
                component: () => import('../page/single/manage/structure/action')
            },
            // 结构监测-结构响应
            {
                path: '/single/structure/response',
                meta: {
                    showBack: false,
                },
                component: () => import('../page/single/manage/structure/response')
            },
            // 结构监测-结构变化
            {
                path: '/single/structure/change',
                meta: {
                    showBack: false,
                },
                component: () => import('../page/single/manage/structure/change')
            },
            // 交通监测-视频监测
            {
                path: '/single/traffic/video',
                meta: {
                    showBack: false,
                },
                component: () => import('../page/single/manage/traffic/video')
            },
            // 交通监测-交通流量
            {
                path: '/single/traffic/flow',
                meta: {
                    showBack: false,
                },
                component: () => import('../page/single/manage/traffic/flow/index1')
            },
            // 交通监测-荷载监测
            {
                path: '/single/traffic/load',
                meta: {
                    showBack: false,
                },
                component: () => import('../page/single/manage/traffic/load/index1')
            },
            // 交通监测-荷载响应
            {
                path: '/single/traffic/response',
                meta: {
                    showBack: false,
                },
                component: () => import('../page/single/manage/traffic/response')
            },
            // 交通监测-智能抓拍
            {
                path: '/single/traffic/capture',
                meta: {
                    showBack: false,
                },
                component: () => import('../page/single/manage/traffic/capture')
            },
            // 交通监测-车辆数据查询
            {
                path: '/single/traffic/car/data',
                meta: {
                    showBack: false,
                },
                component: () => import('../page/single/manage/traffic/carData')
            },
            // 交通监测-交通流量-详情
            {
                path: '/single/traffic/flow/detail/:id',
                meta: {
                    showBack: false,
                },
                component: () => import('../page/single/manage/traffic/flow/detail')
            },
            // 交通监测-荷载监测-详情
            {
                path: '/single/traffic/load/detail/:id',
                meta: {
                    showBack: true,
                },
                component: () => import('../page/single/manage/traffic/load/detail.vue')
            },
            // 交通监测-智能抓拍-详情
            {
                path: '/single/traffic/capture/detail/:id',
                meta: {
                    showBack: true,
                },
                component: () => import('../page/single/manage/traffic/capture/detail.vue')
            },
            // 智能分析-对比分析
            {
                path: '/single/analysis/contrast',
                meta: {
                    showBack: false,
                },
                component: () => import('../page/single/manage/analysis/contrast')
            },
            // 智能分析-数据导出
            {
                path: '/single/analysis/export',
                meta: {
                    showBack: false,
                },
                component: () => import('../page/single/manage/analysis/export')
            },
            // 智能分析-报表分析
            {
                path: '/single/analysis/report',
                meta: {
                    showBack: false,
                },
                component: () => import('../page/single/manage/analysis/report')
            },
            // // 智能分析-相关性分析
            // {
            //     path: '/single/analysis/relate',
            //     meta: {
            //         showBack: false,
            //     },
            //     component: () => import('../page/single/manage/analysis/relate')
            // },
            // // 智能分析-趋势分析
            // {
            //     path: '/single/analysis/trend',
            //     meta: {
            //         showBack: false,
            //     },
            //     component: () => import('../page/single/manage/analysis/trend')
            // },
            // 智能分析-涡振分析
            {
                path: '/single/analysis/vortex',
                meta: {
                    showBack: false,
                },
                component: () => import('../page/single/manage/analysis/vortex')
            },
            // 智能分析-地震分析
            {
                path: '/single/analysis/earthquake',
                meta: {
                    showBack: false,
                },
                component: () => import('../page/single/manage/analysis/earthquake')
            },
            // 智能分析-强台风分析
            {
                path: '/single/analysis/typhoon',
                meta: {
                    showBack: false,
                },
                component: () => import('../page/single/manage/analysis/typhoon')
            },
            // 智能分析-强台风分析
            {
                path: '/single/analysis/ship',
                meta: {
                    showBack: false,
                },
                component: () => import('../page/single/manage/analysis/shipCollision')
            },
            // 智能分析-超载分析
            {
                path: '/single/analysis/overfreight',
                meta: {
                    showBack: false,
                },
                component: () => import('../page/single/manage/analysis/overfreight')
            },
            // 智能分析-异常分析
            {
                path: '/single/analysis/exceptional',
                meta: {
                    showBack: false,
                },
                component: () => import('../page/single/manage/analysis/exceptional')
            },
            // 专项分析-无轴力连接装置分析
            {
                path: '/single/special/connection',
                meta: {
                    showBack: false,
                },
                component: () => import('../page/single/manage/special/connection')
            },
            // 专项分析-光纤光栅分析
            {
                path: '/single/special/optical',
                meta: {
                    showBack: false,
                },
                component: () => import('../page/single/manage/special/optical')
            },
            // 专项分析-荷载对比
            {
                path: '/single/special/load',
                meta: {
                    showBack: false,
                },
                component: () => import('../page/single/manage/special/load')
            },
            // 健康度评估-结构配置
            {
                path: '/single/health/structure',
                meta: {
                    showBack: false,
                },
                component: () => import('../page/single/manage/health/structure')
            },
            // 健康度评估-健康度
            {
                path: '/single/health/degree',
                meta: {
                    showBack: false,
                },
                component: () => import('../page/single/manage/health/healthDegree')
            },
            // 健康度评估-检查指引
            {
                path: '/single/health/examine',
                meta: {
                    showBack: false,
                },
                component: () => import('../page/single/manage/health/examine')
            },
            // 报警管理-阈值管理
            {
                path: '/single/alarm/threshold',
                meta: {
                    showBack: false,
                },
                component: () => import('../page/single/manage/alarm/threshold')
            },
            // 报警管理-报警处理
            {
                path: '/single/alarm/process',
                meta: {
                    showBack: false,
                },
                component: () => import('../page/single/manage/alarm/process')
            },
            // 报警管理-智能抓拍
            {
                path: '/single/alarm/capture',
                meta: {
                    showBack: false,
                },
                component: () => import('../page/single/manage/alarm/capture')
            },
            // 报警管理-抓拍设置
            {
                path: '/single/alarm/capture/setting',
                meta: {
                    showBack: false,
                },
                component: () => import('../page/single/manage/alarm/setting')
            },
            // 桥梁管理-桥梁信息管理
            {
                path: '/single/bridge/info',
                meta: {
                    showBack: false,
                },
                component: () => import('../page/single/manage/bridge/info')
            },
            // 桥梁管理-重大事件管理
            {
                path: '/single/bridge/important',
                meta: {
                    showBack: false,
                },
                component: () => import('../page/single/manage/bridge/important')
            },
            // 桥梁管理-桥梁档案管理
            {
                path: '/single/bridge/archive',
                meta: {
                    showBack: false,
                },
                component: () => import('../page/single/manage/bridge/archive')
            },
            // 桥梁管理-监测信息管理
            {
                path: '/single/bridge/monitor',
                meta: {
                    showBack: false,
                },
                component: () => import('../page/single/manage/bridge/monitor')
            },
            // 设备状况-传感器在线率
            {
                path: '/single/situation/online',
                meta: {
                    showBack: false,
                },
                component: () => import('../page/single/manage/situation/online')
            },
            {
                path: '/single/situation/sensor/detail',
                meta: {
                    showBack: true,
                },
                component: () => import('../page/single/manage/situation/online/sensor')
            },
            {
                path: '/single/situation/history/detail',
                meta: {
                    showBack: true,
                },
                component: () => import('../page/single/manage/situation/online/history')
            },
            // 配置管理-预警配置
            {
                path: '/single/setting/alarm',
                meta: {
                    showBack: false,
                },
                component: () => import('../page/single/manage/setting/alarm')
            },
            // 配置管理-配置管理
            {
                path: '/single/setting/allocation',
                meta: {
                    showBack: false,
                },
                component: () => import('../page/single/manage/setting/allocation')
            },
            // 配置管理-监测项配置
            {
                path: '/single/setting/monitor',
                meta: {
                    showBack: false,
                },
                component: () => import('../page/single/manage/setting/monitor')
            },
            // 配置管理-展示配置
            {
                path: '/single/setting/show',
                meta: {
                    showBack: false,
                },
                component: () => import('../page/single/manage/setting/show')
            },
            // 巡检养护-巡检记录
            {
                path: '/single/inspection/records',
                meta: {
                    showBack: false,
                },
                component: () => import('../page/single/manage/inspection/records')
            },
              // 巡检养护-巡检记录
              {
                path: '/single/inspection/records/detail/:type/:id',
                meta: {
                    showBack: false,
                },
                component: () => import('../page/single/manage/inspection/records/detail.vue')
            },
            // 巡检养护-巡检配置
            {
                path: '/single/inspection/config',
                meta: {
                    showBack: false,
                },
                component: () => import('../page/single/manage/inspection/config')
            },
            // 系统管理-用户管理
            {
                path: '/single/system/user',
                meta: {
                    showBack: false,
                },
                component: () => import('../page/single/manage/system/user')
            },
            // 系统管理-角色管理
            {
                path: '/single/system/role',
                meta: {
                    showBack: false,
                },
                component: () => import('../page/single/manage/system/role')
            },
            // 系统管理-菜单管理
            {
                path: '/single/system/menu',
                meta: {
                    showBack: false,
                },
                component: () => import('../page/single/manage/system/menu')
            },
            // 除湿监测
            {
                path: '/single/dehumidification',
                meta: {
                    showBack: false,
                },
                component: () => import('../page/single/manage/dehumidification')
            },
        ]

    },
];
export default staticRoutes;
