export default {
  // 用户分类
  _SHOW_TYPE: [
    {
      label: "大屏",
      value: '0'
    },
    {
      label: "统计",
      value: '1'
    },
    {
      label: "分析",
      value: '2'
    },
  ],
  // 成功状态码 -- 0
  SUCCESS_CODE: 0,
  // 成功状态码 -- 200
  SUCCESS_CODE_200: 200,
}
