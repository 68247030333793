import Vue from 'vue'
import App from './App.vue'
import router from "./router";
import http from './config/http'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import store from './store';
import bus from './bus/bus';
import * as echarts from 'echarts';
import 'echarts-gl'
import { directive as clickOutside } from 'v-click-outside-x'
import moment from 'moment'
import Video from 'video.js'
import 'video.js/dist/video-js.css';
import * as d3 from "d3"
Vue.prototype.$d3 = d3;
// window.d3 = d3;

Vue.config.productionTip = false

Vue.use(ElementUI);

// 注册自定义组件(全局组件)
import CTitle from "./components/CTitle/index.vue";
Vue.component("CTitle", CTitle);
import CTable from "./components/CTable/index.vue";
Vue.component("CTable", CTable);
import CForm from "./components/CForm/index.vue";
Vue.component("CForm", CForm);
import CFormItem from "./components/CForm/CFormItem.vue";
Vue.component("CFormItem", CFormItem);
import CSearch from "./components/CSearch/index.vue";
Vue.component("CSearch", CSearch);
import TableBtns from "./components/TableBtns/index.vue";
Vue.component("TableBtns", TableBtns);
import CCollapseItem from "./components/CCollapseItem/index.vue";
Vue.component("CCollapseItem", CCollapseItem);
import CDescription from "./components/CDescription/index.vue";
Vue.component("CDes", CDescription);

// 注册指令
Vue.directive('clickOutside', clickOutside)
Vue.prototype.$bus = bus;
Vue.prototype.$echarts = echarts;
Vue.prototype.$http = http; // 绑定请求封装
let bridgePrefix = localStorage.getItem("baseUrl");
Vue.prototype.$MQURL = "ws://"+window.location.host+"/mqsocket/" + bridgePrefix + "/";
// Vue.prototype.$MQ_USER = "zlfbridge";
// Vue.prototype.$MQ_PWD = "zlf@2021";
Vue.prototype.$MQ_USER = "bridgemqtt";
Vue.prototype.$MQ_PWD = "bridge@Hubei_wh43";
Vue.prototype.$moment = moment;
Vue.prototype.$video = Video;
Vue.prototype.$BRIDGE_VIDEO_LIST = {
  "djkhj": {
    upStream: [
      {
        id: "3f3d281820d54ba6a5e3397c71fc7a28",
        name: "跨中上游",
        url: "http://hls01open.ys7.com/openlive/3f3d281820d54ba6a5e3397c71fc7a28.m3u8",
      },
      {
        id: "2eadca5553be4213b4e13b3b8d9510ff",
        name: "8#塔顶上游",
        url: "http://hls01open.ys7.com/openlive/2eadca5553be4213b4e13b3b8d9510ff.m3u8",
      },
      {
        id: "1e2b88fb38ba4e60a86437b1aa888b53",
        name: "9#塔顶上游",
        url: "http://hls01open.ys7.com/openlive/1e2b88fb38ba4e60a86437b1aa888b53.m3u8",
      },
      {
        id: "a55e674e57ed472f91fd1662a60cf6d7",
        name: "8#塔底",
        url: "http://hls01open.ys7.com/openlive/a55e674e57ed472f91fd1662a60cf6d7.m3u8",
      },
      {
        id: "70abfa91f6d94fefbd3661751c0f30b8",
        name: "9#塔底",
        url: "http://hls01open.ys7.com/openlive/70abfa91f6d94fefbd3661751c0f30b8.m3u8",
      },
    ],
    downStream: [
      {
        id: "aba63959abe54a1eb09ffd23971fcd57",
        name: "跨中下游",
        url: "http://hls01open.ys7.com/openlive/aba63959abe54a1eb09ffd23971fcd57.m3u8",
      },
      {
        id: "ec1b9ef40981477387945e6bbf01f6a4",
        name: "8#塔顶下游",
        url: "http://hls01open.ys7.com/openlive/ec1b9ef40981477387945e6bbf01f6a4.m3u8",
      },
      {
        id: "2cb6783a1f9f45c6aeba59a87042a249",
        name: "9#塔顶下游",
        url: "http://hls01open.ys7.com/openlive/2cb6783a1f9f45c6aeba59a87042a249.m3u8",
      },
    ]
  },
  "djk": {
    upStream: [
      {
        id: "3d81078c4d7048f7b0555932714ed30a",
        name: "0#上游",
        url: "http://hls01open.ys7.com/openlive/3d81078c4d7048f7b0555932714ed30a.m3u8",
      },
    ],
    downStream: [
      {
        id: "b0e23a8a94d5481fa95c0ffacf102516",
        name: "1,2#下游航道",
        url: "http://hls01open.ys7.com/openlive/b0e23a8a94d5481fa95c0ffacf102516.m3u8",
      },
      {
        id: "7425054197fb440ba0a4e6a2fada73db",
        name: "2,3#下游航道",
        url: "http://hls01open.ys7.com/openlive/7425054197fb440ba0a4e6a2fada73db.m3u8",
      },
    ]
  },
  "kfy": {
    upStream: [
      {
        id: "6e199ff6b23f4001813fef009cea38a3",
        name: "上游",
        url: "http://hls01open.ys7.com/openlive/6e199ff6b23f4001813fef009cea38a3.m3u8",
      },
    ],
    downStream: [
      {
        id: "9f88799114dd45979fe4ff515d39aed3",
        name: "下游",
        url: "http://hls01open.ys7.com/openlive/9f88799114dd45979fe4ff515d39aed3.m3u8",
      },
    ]
  },
  "zjh": {
    upStream: [
      {
        id: "b497831282224f83bbb99a28e670b654",
        name: "上游",
        url: "http://hls01open.ys7.com/openlive/b497831282224f83bbb99a28e670b654.m3u8",
      },
    ],
    downStream: [
      {
        id: "d6e55eae22da4c3f83cb90dac2b3c9d0",
        name: "下游",
        url: "http://hls01open.ys7.com/openlive/d6e55eae22da4c3f83cb90dac2b3c9d0.m3u8",
      },
    ]
  },
  "hyh": {
    upStream: [
      {
        id: "94b485f0276f455b9453c8677d0d1e2f",
        name: "跨中桥面上游侧1",
        url: "http://hls01open.ys7.com/openlive/94b485f0276f455b9453c8677d0d1e2f.m3u8",
      },
      {
        id: "2d0e00f1a4454d34811900e74da17cbe",
        name: "跨中桥面上游侧2",
        url: "http://hls01open.ys7.com/openlive/2d0e00f1a4454d34811900e74da17cbe.m3u8",
      },
      {
        id: "e1d0bb710d2043b4834a87ced48bb562",
        name: "三号拱座上游侧",
        url: "http://hls01open.ys7.com/openlive/e1d0bb710d2043b4834a87ced48bb562.m3u8",
      },
    ],
    downStream: [
      {
        id: "463f5cbd504d45d68ddcc4a996e2f375",
        name: "4号台下游侧",
        url: "http://hls01open.ys7.com/openlive/463f5cbd504d45d68ddcc4a996e2f375.m3u8",
      },
    ]
  },
  "jwx": {
    upStream: [
      {
        id: "193cd1e9b16546719c9fffb4da4b7fa7",
        name: "0#台",
        url: "http://hls01open.ys7.com/openlive/193cd1e9b16546719c9fffb4da4b7fa7.m3u8",
      },
    ],
    downStream: [
      {
        id: "a56626d42b43437c97f7e0b411261ac8",
        name: "4#台",
        url: "http://hls01open.ys7.com/openlive/a56626d42b43437c97f7e0b411261ac8.m3u8",
      },
    ]
  },
  "qxh": {
    upStream: [
      {
        id: "4f5b3a2604c747aab8c1614e704aad8b",
        name: "上游",
        url: "http://hls01open.ys7.com/openlive/4f5b3a2604c747aab8c1614e704aad8b.m3u8",
      },
    ],
    downStream: [
      {
        id: "70ad16eb1fae4f5a927562b9076fb28a",
        name: "下游",
        url: "http://hls01open.ys7.com/openlive/70ad16eb1fae4f5a927562b9076fb28a.m3u8",
      },
    ]
  },
  "ychj": {
    upStream: [
      {
        id: "0837664b524f4163af015dadd846e668",
        name: "上游1",
        url: "http://hls01open.ys7.com/openlive/0837664b524f4163af015dadd846e668.m3u8",
      },
      {
        id: "dbeb24006b9b4cb1bbc0c0f129ef0d3e",
        name: "上游2",
        url: "http://hls01open.ys7.com/openlive/dbeb24006b9b4cb1bbc0c0f129ef0d3e.m3u8",
      },
    ],
    downStream: [
      {
        id: "a3d491f50f0e4604b6a0d330c811c7a3",
        name: "下游1",
        url: "http://hls01open.ys7.com/openlive/a3d491f50f0e4604b6a0d330c811c7a3.m3u8",
      },
      {
        id: "c2329599279d4aab802a21b3bc1d4b68",
        name: "下游2",
        url: "http://hls01open.ys7.com/openlive/c2329599279d4aab802a21b3bc1d4b68.m3u8",
      },
    ]
  },
  "yxhj": {
    upStream: [
      {
        id: "c022e3cf69e3474c8d85684ab788a260",
        name: "上游1",
        url: "http://hls01open.ys7.com/openlive/c022e3cf69e3474c8d85684ab788a260.m3u8",
      },
      {
        id: "4fe009b7e31546bc8b2b8aabcbb2d0c4",
        name: "上游2",
        url: "http://hls01open.ys7.com/openlive/4fe009b7e31546bc8b2b8aabcbb2d0c4.m3u8",
      },
      {
        id: "6be34747a65841d99ec323a99f6b0481",
        name: "上游3",
        url: "http://hls01open.ys7.com/openlive/6be34747a65841d99ec323a99f6b0481.m3u8",
      },
      {
        id: "cdcc4539e4f4451385e8402f51467204",
        name: "上游4",
        url: "http://hls01open.ys7.com/openlive/cdcc4539e4f4451385e8402f51467204.m3u8",
      },
    ],
    downStream: [
      {
        id: "66edfb22769241f5a936d93a584b35c7",
        name: "下游1",
        url: "http://hls01open.ys7.com/openlive/66edfb22769241f5a936d93a584b35c7.m3u8",
      },
      {
        id: "7811392e3f1a4fba9d78d5471da8eaf0",
        name: "下游2",
        url: "http://hls01open.ys7.com/openlive/7811392e3f1a4fba9d78d5471da8eaf0.m3u8",
      },
      {
        id: "5b7ffde85c9e46c1a06dd25817bb6273",
        name: "下游3",
        url: "http://hls01open.ys7.com/openlive/5b7ffde85c9e46c1a06dd25817bb6273.m3u8",
      },
    ]
  },
  "yyhj": {
    upStream: [
      {
        id: "aef354f2a4d245299ceb47eb849860f9",
        name: "上游1",
        url: "http://hls01open.ys7.com/openlive/aef354f2a4d245299ceb47eb849860f9.m3u8",
      },
      {
        id: "c42ca78f41144bdd86eb50640d8c775a",
        name: "上游2",
        url: "http://hls01open.ys7.com/openlive/c42ca78f41144bdd86eb50640d8c775a.m3u8",
      },
    ],
    downStream: [
      {
        id: "14600da85031411ca7f71a668fb51de5",
        name: "下游1",
        url: "http://hls01open.ys7.com/openlive/14600da85031411ca7f71a668fb51de5.m3u8",
      },
      {
        id: "c7b87da04bbf4455bc169e39f9ee4cc5",
        name: "下游2",
        url: "http://hls01open.ys7.com/openlive/c7b87da04bbf4455bc169e39f9ee4cc5.m3u8",
      },
    ]
  },
  "djksk": {
    upStream: [
      {
        id: "51e847ddc9854ea9b582ac57ba89e548",
        name: "主跨跨中上游桥面",
        url: "http://hls01open.ys7.com/openlive/51e847ddc9854ea9b582ac57ba89e548.m3u8",
      },
      {
        id: "40aac59ac1dc409e9814ae543460a9bc",
        name: "十堰侧塔顶主桥方向",
        url: "http://hls01open.ys7.com/openlive/40aac59ac1dc409e9814ae543460a9bc.m3u8",
      },
      {
        id: "fa1c08cf84f54feaaf1a457b35f416dd",
        name: "十堰侧塔顶引桥方向",
        url: "http://hls01open.ys7.com/openlive/fa1c08cf84f54feaaf1a457b35f416dd.m3u8",
      },
      {
        id: "acfe4e341c3b4e8183fb0ee89c0600c2",
        name: "十堰侧索塔上游侧",
        url: "http://hls01open.ys7.com/openlive/acfe4e341c3b4e8183fb0ee89c0600c2.m3u8",
      },
      {
        id: "3b890d69bb8349ac91db98845c797e0b",
        name: "淅川侧索塔上游侧",
        url: "http://hls01open.ys7.com/openlive/3b890d69bb8349ac91db98845c797e0b.m3u8",
      },
    ],
    downStream: [
      {
        id: "9ae5576eba304d1c8abf24093e7d4920",
        name: "主跨跨中下游侧",
        url: "http://hls01open.ys7.com/openlive/9ae5576eba304d1c8abf24093e7d4920.m3u8",
      },
      {
        id: "a87c2b04e19b44e58aa21ff19026c41b",
        name: "淅川侧塔顶主桥方向",
        url: "http://hls01open.ys7.com/openlive/a87c2b04e19b44e58aa21ff19026c41b.m3u8",
      },
      {
        id: "ab1418ae4ccc4659ac39660dbe4db2b3",
        name: "淅川侧塔顶引桥方向",
        url: "http://hls01open.ys7.com/openlive/ab1418ae4ccc4659ac39660dbe4db2b3.m3u8",
      },
      {
        id: "e859274224714fa782cf861be6f93f9e",
        name: "十堰侧索塔下游侧",
        url: "http://hls01open.ys7.com/openlive/e859274224714fa782cf861be6f93f9e.m3u8",
      },
      {
        id: "4a38e1fb992f4358b5f1a6b51c66bd8e",
        name: "淅川侧索塔下游侧",
        url: "http://hls01open.ys7.com/openlive/4a38e1fb992f4358b5f1a6b51c66bd8e.m3u8",
      },
    ]
  },
  "ghhj": {
    upStream: [
      {
        id: "72b0e524ceac4fd6bbc856d85b41dcbd",
        name: "上游",
        url: "http://hls01open.ys7.com/openlive/72b0e524ceac4fd6bbc856d85b41dcbd.m3u8",
      },
    ],
    downStream: [
      {
        id: "64b962aefdce4aa5b2781b84a3166295",
        name: "下游",
        url: "http://hls01open.ys7.com/openlive/64b962aefdce4aa5b2781b84a3166295.m3u8",
      },
    ]
  },
  "jjh": {
    upStream: [
      {
        id: "f703b688413243a69af35995b85d2ac8",
        name: "上游",
        url: "http://hls01open.ys7.com/openlive/f703b688413243a69af35995b85d2ac8.m3u8",
      },
    ],
    downStream: [
      {
        id: "a6f5148743054ee5889103b4535d725c",
        name: "下游",
        url: "http://hls01open.ys7.com/openlive/a6f5148743054ee5889103b4535d725c.m3u8",
      },
    ]
  },
  "qgh": {
    upStream: [
      {
        id: "8c7ab4a59f83453f84ace7f84b9319b9",
        name: "跨中上游",
        url: "http://hls01open.ys7.com/openlive/8c7ab4a59f83453f84ace7f84b9319b9.m3u8",
      },
    ],
    downStream: [
      {
        id: "f4d8ba2052f74fa7bb381ba9b33fb3e6",
        name: "跨中下游",
        url: "http://hls01open.ys7.com/openlive/f4d8ba2052f74fa7bb381ba9b33fb3e6.m3u8",
      },
    ]
  },
  "ykhj": {
    upStream: [
      {
        id: "5cec5dcac0034d7187a876e523762e81",
        name: "12#墩处桥面上游侧",
        url: "http://hls01open.ys7.com/openlive/5cec5dcac0034d7187a876e523762e81.m3u8",
      },
    ],
    downStream: [
      {
        id: "f4d8ba2052f74fa7bb381ba9b33fb3e6",
        name: "20#墩处桥面下游侧",
        url: "http://hls01open.ys7.com/openlive/765dc817abca45828f076d71e98c4b82.m3u8",
      },
    ]
  },
  "hchj": {
    upStream: [
      {
        id: "6c830856513d426cace9d6cbced37147",
        name: "上游",
        url: "http://hls01open.ys7.com/openlive/6c830856513d426cace9d6cbced37147.m3u8",
      },
    ],
    downStream: [
      {
        id: "1afc5d0eb217410c91715d18f1119ca5",
        name: "下游",
        url: "http://hls01open.ys7.com/openlive/1afc5d0eb217410c91715d18f1119ca5.m3u8",
      },
    ]
  },
  "wsgslsh": {
    upStream: [
      {
        id: "614e2ee425a2422d9de851ae7ccf1150",
        name: "左幅20#墩桥面",
        url: "http://hls01open.ys7.com/openlive/614e2ee425a2422d9de851ae7ccf1150.m3u8"
      },
      {
        id: "14990975e1b744a79e6aa9f8b652708c",
        name: "左幅跨中梁底船撞",
        url: "http://hls01open.ys7.com/openlive/14990975e1b744a79e6aa9f8b652708c.m3u8"
      },
    ],
    downStream: [
      {
        id: "bad3cb7c99f94b6a8ffe3b21326bc1ec",
        name: "右幅23#墩桥面",
        url: "http://hls01open.ys7.com/openlive/bad3cb7c99f94b6a8ffe3b21326bc1ec.m3u8"
      },
      {
        id: "02a9b21a3c25468585109d779c455722",
        name: "右幅跨中梁底船撞",
        url: "http://hls01open.ys7.com/openlive/02a9b21a3c25468585109d779c455722.m3u8"
      },
    ]
  },
  "whshxhj": {
    upStream: [
      {
        id: "whshxhj01",
        name: "上游桥面跨中",
        url: "/videomonitor/whshxhj01/index.m3u8",
      },
      {
        id: "whshxhj03",
        name: "上游3#塔塔顶",
        url: "/videomonitor/whshxhj03/index.m3u8",
      },
      {
        id: "whshxhj05",
        name: "上游4#塔塔顶",
        url: "/videomonitor/whshxhj05/index.m3u8",
      },
    ],
    downStream: [
      {
        id: "whshxhj02",
        name: "下游桥面跨中",
        url: "/videomonitor/whshxhj02/index.m3u8",
      },
      {
        id: "whshxhj04",
        name: "下游3#塔塔顶",
        url: "/videomonitor/whshxhj04/index.m3u8",
      },
      {
        id: "whshxhj06",
        name: "下游4#塔塔顶",
        url: "/videomonitor/whshxhj06/index.m3u8",
      },
    ]
  },
  "khdhytl": {
    upStream: [
      {
        id: "khdhytl01",
        name: "汉阳侧左幅桥边跨跨中",
        url: "/videomonitor/khdhytl01/index.m3u8",
      },
    ],
    downStream: [
      {
        id: "khdhytl02",
        name: "汉阳侧右幅桥边跨跨中",
        url: "/videomonitor/khdhytl02/index.m3u8",
      },
    ]
  },
}
Vue.prototype.$DEFAULT_URL = '/single/structure/environment';
let bridgeList= localStorage.getItem('bridgeList');
if(bridgeList) Vue.prototype.$BRIDGE_NAME_LIST = JSON.parse(bridgeList);
Vue.prototype.$IP = "27.17";

new Vue({
  render: h => h(App),
  router,
  store
}).$mount('#app')


