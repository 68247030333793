<template>
  <el-descriptions-item
    :label="label"
    :span="span"
    :label-class-name="labelClassName"
    :content-class-name="mcontentClassName"
    :label-style="labelStyle"
    :content-style="contentStyle"
    >{{ value }}</el-descriptions-item
  >
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: "",
    },
    value: {
      type: String,
      default: "",
    },
    span: {
      type: Number,
      default: 1,
    },
    labelClassName: {
      type: String,
      default: "",
    },
    contentClassName: {
      type: String,
      default: "",
    },
    labelStyle: {
      type: Object,
    },
    contentStyle: {
      type: Object,
    },
  },
  inject: ["cDes"],
  created() {
    console.log(123);
  },
};
</script>

<style>
</style>