<template>
  <el-row type="flex" class="c-search-item">
    <el-col
      :span="item.span || cSearch.span"
      v-for="(item, index) in searchItems"
      :key="index"
    >
      <el-form-item
        :label="item.label"
        :prop="item.model"
        :label-width="item.labelWidth || cSearch.labelWidth"
        :class="item.clazz"
      >
        <template v-if="item.type == 'select'">
          <el-select
            v-model="cSearch.value[item.model]"
            @change="change($event, item.change)"
            :placeholder="item.placeholder"
            :clearable="item.clearable || false"
            :multiple="item.multiple || false"
            collapse-tags
            :disabled="item.disabled"
          >
            <el-option
              v-for="(option, i) in item.options"
              :key="option.value"
              :label="option.label"
              :value="option.value"
            ></el-option>
          </el-select>
        </template>
        <template v-else-if="item.type == 'year'">
          <el-date-picker
            v-model="cSearch.value[item.model]"
            @change="change($event, item.change)"
            type="year"
            :placeholder="item.placeholder"
            :clearable="item.clearable || false"
            :disabled="item.disabled"
            format="yyyy年"
            value-format="yyyy"
          >
          </el-date-picker>
        </template>
        <template v-else-if="item.type == 'month'">
          <el-date-picker
            v-model="cSearch.value[item.model]"
            @change="change($event, item.change)"
            type="month"
            :placeholder="item.placeholder"
            :clearable="item.clearable || false"
            :disabled="item.disabled"
            format="yyyy年MM月"
            value-format="yyyy-MM"
          >
          </el-date-picker>
        </template>
        <template v-else-if="item.type == 'date'">
          <el-date-picker
            v-model="cSearch.value[item.model]"
            @change="change($event, item.change)"
            type="date"
            :placeholder="item.placeholder"
            :clearable="item.clearable || false"
            format="yyyy年MM月dd日"
            :disabled="item.disabled"
            value-format="yyyy-MM-dd"
          >
          </el-date-picker>
        </template>
        <template v-else-if="item.type == 'datetime'">
          <el-date-picker
            v-model="cSearch.value[item.model]"
            @change="change($event, item.change)"
            :picker-options="item.pickerOptions"
            type="datetime"
            :placeholder="item.placeholder"
            :value-format="item.valueFormat || 'yyyy-MM-dd HH:mm:ss'"
          >
          </el-date-picker>
        </template>
        <template v-else-if="item.type == 'monthrange'">
          <el-date-picker
            v-model="cSearch.value[item.model]"
            @change="change($event, item.change)"
            type="monthrange"
            range-separator="至"
            start-placeholder="开始月份"
            :clearable="item.clearable || false"
            end-placeholder="结束月份"
            format="yyyy年MM月"
            :value-format="item.valueFormat || 'yyyy-MM'"
            :disabled="item.disabled"
          >
          </el-date-picker>
        </template>
        <template v-else-if="item.type == 'daterange'">
          <el-date-picker
            v-model="cSearch.value[item.model]"
            @change="change($event, item.change)"
            type="daterange"
            range-separator="-"
            start-placeholder="开始日期"
            :clearable="item.clearable || false"
            end-placeholder="结束日期"
            format="yyyy年MM月dd日"
            :value-format="item.valueFormat || 'yyyy-MM-dd'"
            :disabled="item.disabled"
          >
          </el-date-picker>
        </template>
        <template v-else-if="item.type == 'datetimerange'">
          <el-date-picker
            v-model="cSearch.value[item.model]"
            @change="change($event, item.change)"
            type="datetimerange"
            range-separator="-"
            start-placeholder="开始时间"
            :value-format="item.valueFormat || 'yyyy-MM-dd HH:mm:ss'"
            :clearable="item.clearable || false"
            end-placeholder="结束时间"
            :disabled="item.disabled"
          >
          </el-date-picker>
        </template>
        <template v-else-if="item.type == 'cascader'">
          <el-cascader
            v-model="cSearch.value[item.model]"
            :options="item.options"
            :props="item.props"
            :show-all-levels="item.showAllLevels && true"
            :clearable="item.clearable || false"
            :collapse-tags="item.collapseTags || false"
            :disabled="item.disabled"
          ></el-cascader>
        </template>
        <template v-else-if="item.type == 'radio'">
          <el-radio-group
            v-model="cSearch.value[item.model]"
            @change="change($event, item.change)"
            :disabled="item.disabled"
          >
            <el-radio
              v-for="option in item.options"
              :key="option.key"
              :label="option.key"
              >{{ option.label }}</el-radio
            >
          </el-radio-group>
        </template>
        <template v-else-if="item.type == 'radioBtn'">
          <el-radio-group
              v-model="cSearch.value[item.model]"
              @change="change($event, item.change)"
              :disabled="item.disabled"
          >
            <el-radio-button
                v-for="option in item.options"
                :key="option.key"
                :label="option.key"
            >{{ option.label }}</el-radio-button
            >
          </el-radio-group>
        </template>
        <template v-else>
          <el-input
            v-model.trim="cSearch.value[item.model]"
            @change="change($event, item.change)"
            :clearable="item.clearable || false"
            :placeholder="item.placeholder"
            :disabled="item.disabled"
          ></el-input>
        </template>
      </el-form-item>
    </el-col>
  </el-row>
</template>

<script>
export default {
  props: {
    searchItems: Array,
  },
  inject: ["cSearch"],
  methods: {
    change(e, fn) {
      fn && fn(e);
    },
    select(e, fn) {
      fn && fn(e);
    },
    clear(e, fn) {
      fn && fn(e);
    },
    nodeClick(e, fn) {
      fn && fn(e);
    },
    check(e, item, fn) {
      let keys = this.$refs.tree[0].getCheckedKeys();
      this.cForm.value[item.model] = keys;
      fn && fn(e);
    },
    checkChange(e, model, fn) {
      fn && fn(e);
    },
    visibleChange(e, fn) {
      fn && fn(e);
    },
    handleClick(e, fn) {
      fn && fn(e);
    },
    focus(e, fn) {
      fn && fn(e);
    },
  },
};
</script>

<style>
.c-search-item .el-form-item {
  text-align: left;
}
.c-search-item .el-form-item--mini.el-form-item,
.c-search-item .el-form-item--small.el-form-item {
  margin-bottom: 10px !important;
}
.c-search-item .el-select,
.c-search-item .el-date-editor.el-input,
.c-search-item .el-date-editor.el-input__inner,
.c-search-item .el-cascader,
.c-search-item .el-input-number--small,
.c-search-item .el-autocomplete,
.c-search-item .el-radio-group {
  width: 100%;
}

.c-search-item .el-checkbox.is-bordered.el-checkbox--small {
  padding-left: 10px;
}

.c-search-item .el-checkbox {
  margin-right: 5px;
}

.c-search-item.el-row--flex {
  flex-wrap: wrap;
}

.c-search-item .el-form-item--small .el-form-item__content {
  line-height: unset;
}
</style>