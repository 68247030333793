<template>
  <el-row type="flex" class="c-form-item">
    <el-col
      :span="item.span || cForm.span"
      v-for="(item, index) in formItems"
      :key="index"
    >
      <el-form-item
        :label="item.label"
        :prop="item.model"
        :label-width="item.labelWidth || cForm.labelWidth"
        :class="item.clazz"
      >
        <template v-if="item.type == 'select'">
          <el-select
            v-model="cForm.value[item.model]"
            @change="change($event, item.change)"
            @focus="focus($event, item.focus)"
            :placeholder="item.placeholder"
            :clearable="item.clearable"
            :filterable="item.filterable"
            :loading="item.loading"
            :remote="item.remote"
            :reserve-keyword="item.reserveKeyword"
            :multiple="item.multiple || false"
            :multiple-limit="item.multipleLimit"
            :remote-method="item.remoteMethod || function () {}"
            :disabled="item.disabled"
          >
            <!-- collapse-tags -->
            <el-option
              v-for="(option, i) in item.options"
              :key="i"
              :label="option.label"
              :value="option.value"
            ></el-option>
          </el-select>
        </template>
        <template v-else-if="item.type == 'year'">
          <el-date-picker
            v-model="cForm.value[item.model]"
            @change="change($event, item.change)"
            type="year"
            :placeholder="item.placeholder"
            :clearable="item.clearable"
            format="yyyy年"
            :disabled="item.disabled"
            value-format="yyyy"
            :picker-options="item.pickerOptions"
          >
          </el-date-picker>
        </template>
        <template v-else-if="item.type == 'month'">
          <el-date-picker
            v-model="cForm.value[item.model]"
            @change="change($event, item.change)"
            type="month"
            :placeholder="item.placeholder"
            :clearable="item.clearable"
            :disabled="item.disabled"
            value-format="yyyy-MM"
            :picker-options="item.pickerOptions"
          >
          </el-date-picker>
        </template>
        <template v-else-if="item.type == 'date'">
          <!-- format="yyyy年MM月dd日" format="yyyy.MM.dd"-->
          <el-date-picker
            v-model="cForm.value[item.model]"
            @change="change($event, item.change)"
            type="date"
            :placeholder="item.placeholder"
            :disabled="item.disabled"
            :clearable="item.clearable"
            value-format="yyyy-MM-dd"
            :picker-options="item.pickerOptions"
          >
          </el-date-picker>
        </template>
        <template v-else-if="item.type == 'monthrange'">
          <el-date-picker
            v-model="cForm.value[item.model]"
            @change="change($event, item.change)"
            type="monthrange"
            range-separator="至"
            start-placeholder="开始月份"
            :clearable="item.clearable"
            end-placeholder="结束月份"
            format="yyyy年MM月"
            :value-format="item.valueFormat || 'yyyy-MM'"
            :picker-options="item.pickerOptions"
          >
          </el-date-picker>
        </template>
        <template v-else-if="item.type == 'daterange'">
          <el-date-picker
            v-model="cForm.value[item.model]"
            @change="change($event, item.change)"
            type="daterange"
            range-separator="-"
            start-placeholder="开始日期"
            :clearable="item.clearable || false"
            end-placeholder="结束日期"
            format="yyyy年MM月dd日"
            :value-format="item.valueFormat || 'yyyy-MM-dd'"
            :disabled="item.disabled"
          >
          </el-date-picker>
        </template>
        <template v-else-if="item.type == 'datetime'">
          <el-date-picker
            v-model="cForm.value[item.model]"
            @change="change($event, item.change)"
            :picker-options="item.pickerOptions"
            type="datetime"
            :placeholder="item.placeholder"
            :value-format="item.valueFormat || 'yyyy-MM-dd HH:mm:ss'"
          >
          </el-date-picker>
        </template>
        <template v-else-if="item.type == 'datetimerange'">
          <el-date-picker
            v-model="cForm.value[item.model]"
            @change="change($event, item.change)"
            :picker-options="item.pickerOptions"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :value-format="item.valueFormat || 'yyyy-MM-dd HH:mm:ss'"
          >
          </el-date-picker>
        </template>

        <template v-else-if="item.type == 'cascader'">
          <el-cascader
            v-model="cForm.value[item.model]"
            :options="item.options"
            :props="item.props"
            :show-all-levels="item.showAllLevels"
            :clearable="item.clearable"
            :disabled="item.disabled"
            @change="change($event, item.change)"
          ></el-cascader>
        </template>
        <template v-else-if="item.type == 'tree'">
          <el-tree
            :ref="item.ref || 'tree'"
            :data="item.data"
            :props="item.props"
            :show-checkbox="item.showCheckbox"
            :expand-on-click-node="item.expandOnClickNode"
            :default-expand-all="item.defaultExpandAll"
            :check-strictly="item.checkStrictly"
            :default-checked-keys="cForm.value[item.model]"
            :node-key="item.nodeKy || 'id'"
            :highlight-current="item.highlightCurrent"
            :allow-drag="item.allowDrag || function () {}"
            @node-click="nodeClick($event, item.nodeClick)"
            @check="check($event, item, item.check)"
            @check-change="checkChange($event, item, item.checkChange)"
          ></el-tree>
        </template>
        <template v-else-if="item.type == 'radio'">
          <el-radio-group
            v-model="cForm.value[item.model]"
            @change="change($event, item.change)"
            :disabled="item.disabled"
          >
            <el-radio
              v-for="option in item.options"
              :key="option.value"
              :label="option.value"
              >{{ option.label }}</el-radio
            >
          </el-radio-group>
        </template>
        <template v-else-if="item.type == 'checkbox'">
          <el-checkbox-group
            v-model="cForm.value[item.model]"
            @change="change($event, item.change)"
          >
            <el-checkbox
              v-for="(option, i) in item.options"
              :key="i"
              :label="option.value"
              >{{ option.label }}</el-checkbox
            >
          </el-checkbox-group>
        </template>
        <template v-else-if="item.type == 'number'">
          <el-input-number
            v-model="cForm.value[item.model]"
            @change="change($event, item.change)"
            :controls-position="item.controlsPosition || 'right'"
            :min="item.min == undefined ? 1 : item.min"
            :max="item.max || 100"
            :step="item.step || 1"
          ></el-input-number>
        </template>
        <template v-else-if="item.type == 'upload'">
          <el-upload
            :action="item.action"
            :file-list="cForm.value[item.model]"
            :multiple="item.multiple"
            :limit="item.limit || 3"
            :show-file-list="item.showFileList"
            :headers="item.headers"
            :disabled="item.disabled"
            :accept="item.accept"
            :on-preview="item.onPreview || function () {}"
            :on-remove="item.onRemove || function () {}"
            :on-success="item.onSuccess || function () {}"
            :on-error="item.onError || function () {}"
            :on-progress="item.onProgress || function () {}"
            :on-change="item.onChange || function () {}"
            :before-upload="item.beforeUpload || function () {}"
            :before-remove="item.beforeRemove || function () {}"
            :on-exceed="item.onExceed || function () {}"
          >
            <el-button size="mini" type="primary" class="btn-primary" :disabled="item.disabled"
              >上传文件</el-button
            >
            <div slot="tip" v-if="item.tip">{{ item.tip }}</div>
          </el-upload>
        </template>
        <template v-else-if="item.type == 'textarea'">
          <el-input
            type="textarea"
            v-model="cForm.value[item.model]"
            @change="change($event, item.change)"
            :clearable="item.clearable"
            :placeholder="item.placeholder"
            :disabled="item.disabled"
            :rows="item.rows"
            :maxlength="item.maxlength"
          ></el-input>
        </template>
        <template v-else-if="item.type == 'autocomplete'">
          <el-autocomplete
            v-model="cForm.value[item.model]"
            :fetch-suggestions="item.fetchSuggestions || function () {}"
            :placeholder="item.placeholder"
            :clearable="item.clearable"
            :trigger-on-focus="item.triggerOnFocus"
            @select="select($event, item.select)"
            @clear="clear($event, item.clear)"
          ></el-autocomplete>
        </template>
        <template v-else-if="item.type == 'selectInput'">
          <el-input
            :placeholder="item.placeholder"
            v-model="cForm.value[item.model]"
            class="input-with-select"
          >
            <el-select
              v-model="cForm.value[item.select.model]"
              slot="prepend"
              placeholder="请选择"
            >
              <el-option
                v-for="(option, i) in item.select.options"
                :key="i"
                :label="option.label"
                :value="option.value"
              ></el-option>
            </el-select>
          </el-input>
        </template>
        <template v-else-if="item.type == 'buttonInput'">
          <div class="button-input">
            <el-input
              v-model.trim="cForm.value[item.model]"
              @change="change($event, item.change)"
              :clearable="item.clearable"
              :placeholder="item.placeholder"
              :disabled="item.disabled"
            >
            </el-input>
            <el-button type="primary" @click="handleClick($event, item.click)"
              >请选择</el-button
            >
          </div>
        </template>
        <template v-else-if="item.type == 'button'">
          <el-button
            :type="item.type"
            :size="item.size"
            :plain="item.plain"
            :round="item.round"
            :loading="item.loading"
            :disabled="item.disabled"
            :icon="item.icon"
            :circle="item.circle"
            :class="item.class"
            @click="handleClick($event, item.click)"
          ></el-button>
        </template>
        <template v-else>
          <el-input
            v-model.trim="cForm.value[item.model]"
            @change="change($event, item.change)"
            :clearable="item.clearable"
            :placeholder="item.placeholder"
            :disabled="item.disabled"
            :show-password="item.password"
          ></el-input>
        </template>
      </el-form-item>
    </el-col>
  </el-row>
</template>

<script>
export default {
  props: {
    formItems: Array,
    cForm: Object,
  },
  inject: ["cForm"],
  methods: {
    change(e, fn) {
      fn && fn(e);
    },
    select(e, fn) {
      fn && fn(e);
    },
    clear(e, fn) {
      fn && fn(e);
    },
    nodeClick(e, fn) {
      fn && fn(e);
    },
    check(e, item, fn) {
      let keys = this.$refs.tree[0].getCheckedKeys();
      this.cForm.value[item.model] = keys;
      fn && fn(e);
    },
    checkChange(e, model, fn) {
      fn && fn(e);
    },
    visibleChange(e, fn) {
      fn && fn(e);
    },
    handleClick(e, fn) {
      fn && fn(e);
    },
    focus(e, fn) {
      fn && fn(e);
    },
  },
};
</script>

<style>
.c-form-item .el-form-item {
  text-align: left;
}
.c-form-item .el-form-item--mini.el-form-item,
.c-form-item .el-form-item--small.el-form-item {
  margin-bottom: 18px !important;
}

.c-form-item .el-select,
.c-form-item .el-date-editor.el-input,
.c-form-item .el-date-editor.el-input__inner,
.c-form-item .el-cascader,
.c-form-item .el-input-number--small,
.c-form-item .el-autocomplete,
.c-form-item .el-radio-group {
  width: 100%;
}

.c-form-item .el-checkbox.is-bordered.el-checkbox--small {
  padding-left: 10px;
}

.c-form-item .el-checkbox {
  margin-right: 5px;
}
.c-form-item  .el-radio {
  line-height:32px;
}
.c-form-item.el-row--flex {
  flex-wrap: wrap;
}

.input-with-select .el-select .el-input {
  width: 130px;
}
.input-with-select .el-input-group__prepend {
  background-color: #fff;
}

</style>