<script>
export default {
  inject: ["cTable"],
  props: ["item"],
  render(h) {
    let slot = this.cTable.$scopedSlots[this.item.name];
    if (!slot) {
      slot = () => [];
    }
    return h("el-table-column", {
      attrs: Object.assign(
        {},
        {
          showTooltipWhenOverflow: true,
          align: "center",
          "header-align": "center",
          "min-width": "100px",
          fixed: this.item.fixed ? item.fixed : false,
        },
        this.item
      ),
      scopedSlots: {
        default: (scope) =>
          slot({
            row: scope.row,
            column: scope.column,
            $index: scope.$index,
          }),
      },
    });
  },
};
</script>
