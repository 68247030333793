<template>
  <el-descriptions
    :title="title"
    :column="column"
    :border="border"
    :direction="direction"
    :colon="colon"
    :size="size"
    :labelClassName="labelClassName"
    :contentClassName="contentClassName"
    :labelStyle="labelStyle"
    :contentStyle="contentStyle"
  >
    <template slot="title">
      <slot name="title"></slot>
    </template>
    <template slot="extra">
      <slot name="extra"></slot>
    </template>
    <el-descriptions-item
      v-for="(item, index) in desItems"
      :key="index"
      :label="item.label"
      :span="item.span"
      :label-class-name="item.labelClassName"
      :content-class-name="item.mcontentClassName"
      :label-style="item.labelStyle"
      :content-style="item.contentStyle"
      >{{ value[item.model] }}</el-descriptions-item
    >
  </el-descriptions>
</template>

<script>
import CDescriptionItem from "./CDescriptionItem.vue";
export default {
  components: {
    CDescriptionItem,
  },
  props: {
    value: {
      type: Object,
    },
    desItems: {
      type: Array,
    },
    title: {
      type: String,
      default: "",
    },
    column: {
      type: Number,
      default: 3,
    },
    border: {
      type: Boolean,
      default: true,
    },
    direction: {
      type: String,
      default: "horizontal",
    },
    colon: {
      type: Boolean,
      default: true,
    },
    size: {
      type: String,
      default: "",
    },
    labelClassName: {
      type: String,
      default: "",
    },
    contentClassName: {
      type: String,
      default: "",
    },
    labelStyle: {
      type: Object,
    },
    contentStyle: {
      type: Object,
    },
  },
  created() {},
  watch: {
    desItems: {
      handler(val) {
        // console.log(val);
      },
      deep: true,
      immediate: true,
    },
    value: {
      handler(val) {
        console.log(val);
      },
      deep: true,
      immediate: true,
    },
  },
  provide() {
    return {
      cDes: this,
    };
  },
};
</script>

<style>
</style>