import Vuex from "vuex"
import Vue from 'vue'
import vuexModules from "./modules";

Vue.use(Vuex);
export default new Vuex.Store({
    state: {
    },
    mutations: {
    },
    actions: {
    },
    modules: vuexModules
})