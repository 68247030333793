const state = {
    // 用户名
    name: ''
};

const mutations = {
    setName: (state, data) => {
        if (data) {
            localStorage.setItem('userName', encodeURIComponent(data));
        } else {
            localStorage.removeItem('userName');
        }
        state.name = data
    }
}

export default {
    namespaced: true,
    state,
    mutations
}
