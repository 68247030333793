<template>
  <div class="group-btn-right">
    <div class="img-box" @click="handleRefresh">
      <i class="iconfont icon-shuaxin btn-icon" title="刷新"></i>
    </div>
    <el-popover placement="bottom" trigger="click">
      <div class="img-box column-box" ref="column" slot="reference">
        <i class="el-icon-menu btn-icon" title="列"></i>
      </div>
      <div class="dropdown-box div-scroll" :class="{ active: isColumnShow }">
        <div style="padding: 10px">
          <el-checkbox-group
            v-model="checkList"
            @change="handleTableColumnChange"
          >
            <div v-for="(checkItem, index) in tableColumns" :key="index">
              <el-checkbox :label="checkItem.label" v-if="checkItem.label">{{
                checkItem.label
              }}</el-checkbox>
            </div>
          </el-checkbox-group>
        </div>
      </div>
    </el-popover>
  </div>
</template>

<script>
export default {
  name: "",
  props: {
    tableColumns: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      isColumnShow: false,
      checkList: [],
      oldVal: [],
    };
  },
  watch: {
    tableColumns: {
      handler(newVal) {
        if (newVal.length != this.oldVal.length) {
          // console.log(newVal);
          // 更新选中的值
          this.checkList = [];
          this.getCheckList(newVal);
        }
        if (newVal) {
          // 记录上一次的值
          this.oldVal = [...newVal];
        }
      },
      deep: true,
      immediate: true,
    },
  },
  created() {
    // this.tableColumns.forEach((item) => {
    //     this.checkList.push(item.prop);
    //   });
  },
  mounted() {},
  methods: {
    getCheckList(columns) {
      columns.forEach((item) => {
        this.checkList.push(item.label);
      });
    },
    //   刷新表格
    handleRefresh() {
      this.$emit("refresh");
    },
    // 动态设置列的显示和隐藏
    handleTableColumnChange(data) {
      this.$emit("tableColumnsChange", data);
    },
  },
  beforeDestroy() {},
};
</script>
